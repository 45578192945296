import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown  } from '@fortawesome/free-solid-svg-icons'

import logo from "../images/pemalex_logo.png"
import * as navbarStyles from "./navbar.module.css"

const ListLink = props => (
  <li className={navbarStyles.navItem}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default function Navbar({ children }) {
  return (
    <nav className="navbar sticky-top navbar-expand-lg bg-light">
      <div className="container">
        <Link to="/">
            <img src={logo} alt="Logo" className={navbarStyles.logo} />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <FontAwesomeIcon icon={faCaretDown} className="mb-2" size="lg"/>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className={`navbar-nav w-100 justify-content-end ${navbarStyles.links}`}>
            <ListLink to="/">Home</ListLink>
            <ListLink to="/about/">Sobre</ListLink>
            <ListLink to="/product/">Produtos</ListLink>
            <ListLink to="/contact/">Contato</ListLink>
            <ListLink to="/amare/" target="_blank" rel="noreferrer noopener">Amaré</ListLink>
        </ul>
      </div>
    </div>
  </nav>
  )
}