import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import logo from "../images/pemalex_logo.png"
import * as footerStyles from "./footer.module.css"

export default function Footer({ children }) {
  return (
    <div className={footerStyles.container}>
        <div className="row my-2">
            <div className="col-lg align-self-start text-center order-lg-first my-5">
                <h4 className={`${footerStyles.title} mb-4 text-uppercase`}>Fábrica</h4>
                <p className="text-nowrap overflow-hidden">Av. Dr. Mendel Steinbruch, km 7, s/n</p>
                <p>Maracanaú-CE</p>
                <p>+55 (85) 3401-2014</p>
                <p>contato@pemalex.com.br</p>
            </div>
            <div className="col-lg align-self-center my-md-5 order-first">
                <img src={logo} alt="Pemalex Logo" className={footerStyles.logo} />
            </div>
            <div className="col-lg align-self-end text-center my-5">
                <h4 className={`${footerStyles.title} mb-4 text-uppercase`}>Estamparia</h4>
                <p>Amaré Design</p>
                <p>Maracanaú-CE</p>
                <FontAwesomeIcon icon={faInstagram} className="mr-1"/><a href="https://www.instagram.com/amaredesign/">@amaredesign</a>
                <p>amare@pemalex.com.br</p>
            </div>
        </div>
    </div>
  )
}